















import Vue from 'vue';
import axios from 'axios';
import * as firebase from 'firebase';
import 'firebase/auth';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map, switchMap } from 'rxjs/operators';

export default Vue.extend({
  data() {
    return {
      password: '',
      logging: false
    }
  },
  methods: {
    doLogin() {
      if (this.logging) return;
      this.logging = true;
      fromPromise(axios.post('/auth/login', { password: this.password }))
        .pipe(
            switchMap(() => fromPromise(axios.get('/api/token/create'))),
            map(r => r.data),
            switchMap((t) => firebase.auth().signInWithCustomToken(t))
        )
        .subscribe(() => this.$router.push('/'), err => {
          console.error(err);
          this.logging = false;
        });
    }
  }
});
