// Dependencies
import Vue from 'vue';
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue';
import VueRouter from 'vue-router';
import { firestorePlugin } from 'vuefire';
import * as firebase from 'firebase/app';

// Style
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.css'
import 'animate.css/animate.css'

// Vue components
import App from './vue/App';
import Dashboard from "./vue/Dashboard";
import Login from "./vue/Login";
import Logout from "./vue/Logout";

// Install Bootstrap vue
Vue.use(firestorePlugin);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAN41B0LPkDKoqiLRXuCnQvR_G7sVYE41Y",
  authDomain: "platinmarket-reform.firebaseapp.com",
  databaseURL: "https://platinmarket-reform.firebaseio.com",
  projectId: "platinmarket-reform",
  storageBucket: "platinmarket-reform.appspot.com",
  messagingSenderId: "188360825577",
  appId: "1:188360825577:web:c9613927e78a57b1ca2e78"
};
firebase.initializeApp(firebaseConfig);

// Routes
const routes = [
  { path: '/', component: Dashboard },
  { path: '/login', component: Login },
  { path: '/logout', component: Logout }
];

// Create router
const router = new VueRouter({
  routes
});

// Render application
const app = new Vue({
  render: createElement => createElement(App),
  router
}).$mount('#app');
