




import Vue from 'vue';
import axios from 'axios';
import * as firebase from 'firebase/app';
import { fromPromise } from 'rxjs/internal-compatibility';
import 'firebase/auth';
import { forkJoin } from 'rxjs';

export default Vue.extend({
  data() {
    return {}
  },
  mounted() {
    forkJoin([
        fromPromise(axios.get('/auth/logout')),
        fromPromise(firebase.app().auth().signOut())
    ]).subscribe(() => this.$router.push('/login'));

  }
});
